import React, { FC, SVGAttributes } from "react"
import SVG from "./svg"

const Sparkle: FC<SVGAttributes<SVGElement>> = (props) => {
  return (
    <SVG
      viewBox="0 0 29 30"
      {...props}
    >
      <path
        d="M2.276 6.017l-2.142.79a.205.205 0 000 .385l2.143.79a6.317 6.317 0 013.74 3.741l.79 2.143a.205.205 0 00.385 0l.79-2.143a6.317 6.317 0 013.741-3.74l2.143-.79a.205.205 0 000-.386l-2.142-.79a6.317 6.317 0 01-3.741-3.74L7.193.133a.205.205 0 00-.385 0l-.79 2.142a6.316 6.316 0 01-3.742 3.741zM15.134 14.807l2.142-.79a6.317 6.317 0 003.741-3.74l.79-2.143a.205.205 0 01.385 0l.79 2.142a6.317 6.317 0 003.742 3.741l2.142.79a.205.205 0 010 .385l-2.143.79a6.317 6.317 0 00-3.74 3.741l-.79 2.143a.205.205 0 01-.385 0l-.79-2.143a6.316 6.316 0 00-3.741-3.74l-2.143-.79a.205.205 0 010-.386zM3.134 22.808l2.142-.79a6.316 6.316 0 003.741-3.742l.79-2.142a.205.205 0 01.385 0l.79 2.142a6.317 6.317 0 003.742 3.741l2.142.79a.205.205 0 010 .385l-2.143.79a6.317 6.317 0 00-3.74 3.741l-.79 2.143a.205.205 0 01-.385 0l-.79-2.143a6.316 6.316 0 00-3.741-3.74l-2.143-.79a.205.205 0 010-.386z"
      ></path>
    </SVG>
  )
}

export default Sparkle
