import { graphql } from "gatsby";

export interface PokemonFormInfo {
    base_attack: number;
    base_defense: number;
    base_stamina: number;
    fast_moves: string[];
    charged_moves: string[];
    types: string[];
    image?: any;
    forms: Record<PokemonMegaForm, {
        name: string
        firstTimeMegaEnergyRequired: number
        megaEnergyRequired: number
        base_attack: number
        base_defense: number
        base_stamina: number
        type: string[]
        image?: any

    }>
}
export interface Pokemon {
    name: string;
    pokemonId: number;
    forms: Record<Partial<PokemonForm>, PokemonFormInfo>;
    shinyAvailable?: boolean;
    released?: boolean;
    elite_fast_moves: string[];
    elite_charged_moves: string[];
}

export interface PokemonReference {
    pokemonId: number;
    shiny?: boolean;
    form: PokemonForm;
    megaForm?: PokemonMegaForm;
    costume?: PokemonCostume;
}

export enum PokemonForm {
    Normal = 'normal',
    Shadow = 'shadow',
    Purified = 'purified',
    Mega = 'mega',
    Alola = 'alola',
    Altered = 'altered',
    Galarian = 'galarian',
    Incarnate = 'incarnate',
    Origin = 'origin',
    Therian = 'therian',
}

export enum PokemonCostume {
    SantaHat = 'santa-hat',
    FlowerCrown = 'flower-crown',
}

export enum PokemonMegaForm {
    Normal = 'normal',
    X = 'x',
    Y = 'y',
}

export interface PokemonNode {
    node: Pokemon;
}

export const getPokemonForm = ({ form, megaForm }: PokemonReference, pokemon: Pokemon) => form === PokemonForm.Mega && megaForm ? pokemon.forms[form].forms[megaForm] : pokemon.forms[form]

export const POKEMON_CORE_FIELDS = graphql`
    fragment PokemonFields on Pokemon {
        pokemonId
        name
        shinyAvailable
        released
        elite_fast_moves
        elite_charged_moves
        forms {
            therian {
                base_defense
                base_attack
                base_stamina
                charged_moves
                fast_moves
                types
            }
            shadow {
                base_defense
                base_attack
                base_stamina
                charged_moves
                fast_moves
                types
            }
            purified {
                base_defense
                base_attack
                base_stamina
                charged_moves
                fast_moves
                types
            }
            origin {
                base_defense
                base_attack
                base_stamina
                charged_moves
                fast_moves
                types
            }
            normal {
                base_defense
                base_attack
                base_stamina
                charged_moves
                fast_moves
                types
            }
            incarnate {
                base_defense
                base_attack
                base_stamina
                charged_moves
                fast_moves
                types
            }
            galarian {
                base_defense
                base_attack
                base_stamina
                charged_moves
                fast_moves
                types
            }
            altered {
                base_defense
                base_attack
                base_stamina
                charged_moves
                fast_moves
                types
            }
            alola {
                base_defense
                base_attack
                base_stamina
                charged_moves
                fast_moves
                types
            }
            mega {
                forms {
                    normal {
                        name
                        firstTimeMegaEnergyRequired
                        megaEnergyRequired
                        base_attack
                        base_defense
                        base_stamina
                        type
                    }
                    x {
                        name
                        firstTimeMegaEnergyRequired
                        megaEnergyRequired
                        base_attack
                        base_defense
                        base_stamina
                        type
                    }
                    y {
                        name
                        firstTimeMegaEnergyRequired
                        megaEnergyRequired
                        base_attack
                        base_defense
                        base_stamina
                        type
                    }
                }
            }
        }
    }
`