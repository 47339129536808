import { graphql, useStaticQuery } from "gatsby"
import { Pokemon } from "../models/pokemon"

export const useAllPokemon = (): Array<{ node: Pokemon }> => {
    const { allPokemon: { edges: pokemon }, allFile: { edges: pokemonImages } } = useStaticQuery(graphql`
        query Pokemon {
            allPokemon {
                edges {
                    node {
                        ...PokemonFields
                    }
                }
            }
            allFile(filter: {sourceInstanceName: {eq: "pokemon-images"}}) {
                edges {
                    node {
                        name
                        childImageSharp {
                            # Specify the image processing specifications right in the query.
                            # Makes it trivial to update as your page's design changes.
                            fluid(maxWidth: 200) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    `)
    pokemonImages.map(({ node: { name, childImageSharp} }: any) => {
        const match = (name as string).match(/(?:pokemon-)(\d{1,3})(?:-)?(\w*)?/)
        const megaMatch = (name as string).match(/(?:pokemon-)(\d{1,3})(?:-mega)(?:-(x|y)?)?/)
        const acceptedForms = ['normal', 'shadow', 'purified', 'therian', 'alola', 'altered', 'galarian', 'incarnate', 'origin', 'therian']

        try {
            if (!match) {
                return
            }
            const id = Number(match[1])
            if (pokemon[id - 1] && !megaMatch && pokemon[id - 1].node.forms) {
                const form = acceptedForms.includes(match[2]) ? match[2] : 'normal'
                pokemon[id - 1].node.forms[form].image = childImageSharp
            }
            if (pokemon[id - 1] && megaMatch) {
                const megaId = Number(megaMatch[1])
                const variation = megaMatch[2] ?? 'normal'
                pokemon[megaId - 1].node.forms['mega'].forms[variation].image = childImageSharp
            }
        } catch (e) {
            console.log(e)
        }
        
    })

    return pokemon
}